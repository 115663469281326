:root {
  --text: 1vw;
  --navTxt: calc(1vh + 1.3vw);
}

.hasBootstrap {
  @import '../node_modules/bootstrap/scss/bootstrap';
}

.carouselSec {
  grid-area: 1/1/2/2;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.carousel {
  height: 100%;
}
.carouselAni {
  height: 100%;
}
.carousel-item {
  transition-duration: 0.8s !important;
  height: 100%;
}
.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: grid !important;
  grid-template: calc(var(--navTxt) * 10) auto / 1fr !important;
  background: var(--cardBckgrnd) !important;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 2.1em;
  border-bottom-right-radius: 2.1em;
}
.carousel-inner {
  height: 100%;
}
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: rotateY(180deg) !important;
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: rotateY(180deg) !important;
}
.carousel-caption {
  position: relative !important;
  display: inline-block;
  grid-area: 2/1/3/2;
  width: 70%;
}
.carousel-caption p * {
  line-height: 1.2em !important;
  text-align: justify !important;
}
.carousel-caption p {
  line-height: 1.2em !important;
  text-align: justify !important;
}
.carousel-caption h1 {
  font-size: 1.5em !important;
  margin: 0.5em 0 !important;
  text-shadow: var(--txtBxShdw) !important;
}

/* Page Level */
html {
  position: fixed;
  margin: 0;
}
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
  font-size: var(--text);
}
* {
  transition: 0.5s all, 0s font-size;
  color: var(--textColor) !important;
}
#appDiv {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template: 8% 92% / 1fr;
}
main {
  flex-direction: column;
  grid-area: 2/1/3/2;
  overflow-y: scroll;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}
#emailSec {
  grid-area: 1/1/3/2;
  background-color: var(--hoverOver);
  z-index: 2;
  display: none;
  align-items: center;
  justify-content: center;
}
#emailSec * {
  color: black !important;
  font-family: Arial, Helvetica, sans-serif;
}
#emailForm {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: calc(2vh + 2vw);
  background: var(--navBckgrnd);
  box-shadow: var(--navBxShdw);
  width: calc(20vh + 20vw);
}
#emailForm > * {
  display: block;
  padding: 0;
  margin: 0;
}
#emailAddy {
  height: calc(2vh + 1.5vw);
  width: 100%;
}
#emailBody {
  height: calc(5vh + 2.5vw);
  width: 100.5%;
}
#emailBtn {
  height: calc(2vh + 1.5vw);
  width: 101%;
}
#background {
  grid-area: 2/1/3/2;
  grid-template: 50% 50% / 50% 50%;
  display: grid;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow: hidden;
}
#background > * {
  height: 101%;
  width: 101%;
}
video {
  object-fit: fill;
  height: 100.1%;
  width: 100%;
}

.navBtn {
  font-family: inherit;
  font-size: var(--navTxt);
  margin: 0;
  padding: 0;
  text-decoration: underline;
  overflow: visible;
  background: none;
  border: none;
}
a,
.navBtn {
  text-shadow: 0 0 1vh var(--iconBxShdw);
  transition: 0.2s;
}
a:hover,
.navBtn:hover {
  text-shadow: 0 0 1.5vh var(--aShadow);
  color: var(--aHover) !important;
}
a:active,
.navBtn:active {
  text-shadow: 0 0 4vh var(--aShadow);
  color: var(--aActive) !important;
}

/* Nav Bar */
nav {
  grid-area: 1/1/2/2;
  display: grid;
  grid-template: 1fr / 1fr;
  height: 100%;
  max-height: 100%;
  position: relative;
}
#linkSec {
  padding: 0 8vw;
  box-shadow: var(--navBxShdw);
  background: var(--navBckgrnd);
  font-size: var(--navTxt);
  grid-area: 1/1/2/2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 84%;
  height: 8vh;
  position: relative;
}
/* Drop Down Menus */
.dropdown {
  display: flex;
  width: calc(var(--navTxt) * 4);
  align-self: flex-start;
  align-items: center;
  flex-direction: column;
}
// Project Selector
.dropdown > label {
  height: 8vh;
  display: flex;
  align-items: center;
}
.ddList {
  white-space: nowrap;
  width: fit-content;
  flex-direction: column;
  padding: 1vw !important;
  list-style-type: none;
  margin: 0 !important;
  background-color: var(--hoverOver);
  z-index: 2;
}
.ddList > li {
  width: 100%;
  padding: 0 0.5vw;
  height: calc(var(--navTxt) * 2);
  cursor: pointer;
}
.ddList > li:hover {
  background: var(--infoBckgrnd);
}
.linkA {
  background: none;
  border: none;
  padding: 0;
  text-decoration: none !important;
  color: var(--textColor) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: inherit;
  width: inherit;
}

.linkA:hover {
  color: var(--textColor) !important;
}

/* ReCaptcha */
#reCaptchaDD {
  grid-area: 1/1/2/2;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
  overflow: visible;
  align-items: flex-end;
  height: 8vh;
}
#reCaptchaDD div {
  position: relative;
  // z-index: 2;
  height: 76px !important;
  width: 302px !important;
}
/* Theme Slider */
#btnSec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: auto;
  height: 4vh;
}
#btnSlider {
  height: 2vh;
  width: calc(5vh + 1vw);
}
#iconBtn {
  box-shadow: 0 0 5vw 1.5vw var(--iconBxShdw);
  margin: var(--iconMargin);
  display: flex;
  justify-content: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 999px;
  align-items: center;
  border: none;
  height: 2vh;
  max-width: 2vh;
}
#iconBtn > * {
  position: inherit;
  cursor: pointer;
}
#moon {
  height: 4vh;
  opacity: var(--moonOp);
}
#sun {
  height: 4vh;
  opacity: var(--sunOp);
}
#ray {
  height: var(--rayH);
  margin-bottom: 0.2vh;
}

/* Project Display */
.instructions {
  padding: 2vh 2vh 0 2vh;
  font-size: calc(1vh + 1.5vw);
  text-align: center;
}
.pRow {
  justify-content: space-between;
  padding: 5vw 5vw;
  display: flex;
  min-height: 50vw;
  width: 90vw;
}

/* Project Piece */
.pieceCont {
  padding: 0 3vw 3vw 3vw;
  display: grid;
  grid-template: 20% 80% / 1fr;
  height: 32vw;
  width: 50vw;
  box-shadow: var(--pieceBxShdw);
  background: var(--pieceBckgrnd);
  text-align: center;
}
.imgTitle {
  text-shadow: 0 0 1vh var(--iconBxShdw);
  font-weight: normal;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3vw;
  height: 100%;
  width: 100%;
  grid-area: 1/1/2/2;
}
.pieceImg {
  grid-area: 2/1/3/2;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template: 1fr / 1fr;
}
.pieceImg > img {
  grid-area: 1/1/2/2;
  height: 90%;
  width: 100%;
}
.linkDiv {
  height: 90%;
  width: 100%;
  font-size: 2vw;
  position: relative;
  grid-area: 1/1/2/2;
}
.linkDiv > section {
  opacity: 0;
  background-color: var(--hoverOver);
  display: grid;
  grid-template: auto 1fr / 1fr 1fr;
  height: 100%;
  width: 100%;
  position: absolute;
}
.linkDiv > section > * {
  width: fit-content;
}
.linkDiv > section a {
  pointer-events: none;
}
.github {
  grid-area: 2/1/3/2;
  align-self: center;
  justify-self: right;
  font-size: 2.7vw;
  margin: 0;
  margin-right: 6vw;
}
.deployment {
  grid-area: 2/2/3/3;
  align-self: center;
  justify-self: left;
  font-size: 2.7vw;
  margin: 0;
  margin-left: 6vw;
}
.pieceAbout {
  display: inline-block;
  height: fit-content;
  grid-area: 1/1/2/3;
  text-shadow: 0 0 1vh var(--iconBxShdw);
  margin: 1.5vw 2.5vw 0 2.5vw;
  text-align: justify;
  align-self: start;
  justify-self: center;
  text-indent: 3vw;
}

/* Piece Animation */
.aniImg {
  height: 17vw;
  width: 17vw;
  box-shadow: var(--aniImgBxShdw);
  padding: 1vw;
}
.aniDiv {
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 22vw;
  width: 22vw;
  background: var(--aniDivBckgrnd);
  box-shadow: var(--aniDivBxShdw);
}

/* Home Page */

/* Greeting */
#greeting {
  font-size: calc(var(--text) * 3);
  margin: 7vw 0;
}
.header {
  text-shadow: 0 0 1vh var(--iconBxShdw);
}
/* About Me */
#aboutMe {
  display: flex;
  align-items: center;
  flex-direction: column;
}
#info {
  margin: 3vw 10vw;
  display: grid;
  grid-template: 1fr / 40% 60%;
  background: var(--infoBckgrnd);
  border-radius: 30px;
}
#attrCont {
  display: flex;
  align-items: center;
  background: var(--aniDivBckgrnd);
  padding: 3vw;
  font-size: 0.9em;
}
/* Attributes */
#attributes {
  height: inherit;
  grid-area: 1/1/2/2;
  padding-bottom: 0;
}
.attribute {
  display: inline-grid;
  grid-template: repeat(3, auto) / 1fr;
}
.title {
  text-shadow: var(--txtBxShdw);
  padding-right: 1vw;
  margin-bottom: 0.4vw;
  text-align: left;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  grid-area: 1/1/2/2;
  margin: 0;
}
.barSec {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  grid-area: 2/1/3/2;
}
.bar {
  height: 1vw;
  width: 100%;
  display: grid;
  grid-template: 1fr / 1fr;
  border-radius: 10px;
  overflow-y: visible;
  background-color: rgb(247, 247, 247);
  margin-top: 0.2vw;
}
.level {
  transition: 0s;
  grid-area: 1/1/2/2;
  background: linear-gradient(to right, rgb(0, 168, 0), rgb(0, 119, 0));
  width: 0;
  height: inherit;
  border-radius: 10px;
  height: inherit;
}
.reasoning {
  grid-area: 3/1/4/2;
  margin-top: 0.4vw;
  margin-bottom: 0.8vw;
  text-align: justify;
  display: inline-block;
}
#attributes > :last-child > :last-child {
  margin-bottom: 0;
}
.l0 {
  animation: load 1.54s linear;
  animation-iteration-count: 1;
}
.l1 {
  animation: load 1.52s linear;
  animation-iteration-count: 1;
}
.l2 {
  animation: load 1.61s linear;
  animation-iteration-count: 1;
}
.l3 {
  animation: load 1.58s linear;
  animation-iteration-count: 1;
}
.l4 {
  animation: load 1.75s linear;
  animation-iteration-count: 1;
}

/* Backstory */
#backstory {
  grid-area: 1/2/2/3;
  padding: 3vw;
  margin: 0;
}
#backstory > * {
  margin: 0;
}

/* Poke Badges */
#pokeBadges {
  margin: 3vw 10vw 6vw 10vw;
  padding: 2vw 3vw;
  width: 66vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--pbBckgrnd);
  border: ridge var(--logoBorder) 1.5vw;
}
#pokeBadges h2,
h5 {
  margin: 0;
  padding: 0;
}
#pokeBadges h5 {
  margin-bottom: 1vw;
}

#pokeBadges h2,
h3 {
  text-shadow: 0 0 1vh var(--iconBxShdw);
}
.badgeCont {
  width: 100%;
  margin: 1vw 0;
}
#pokeBadges h3 {
  font-size: 1.1em;
  margin: 0;
  text-align: left;
  width: 100%;
}
#pokeBadges p {
  margin: auto;
  font-size: 0.8em;
  text-align: center;
  vertical-align: middle;
}
.badgeDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  align-items: center;
}
.logoDiv {
  width: 6vw;
  display: grid;
  grid-template: 50% 50% / auto;
  height: 7vw;
}
.logoCont {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5vw;
  height: 1.5vw;
  border: outset var(--logoBorder) 0.3vw;
  background: var(--logoBckgrnd);
  box-shadow: 0 0 2vw -0.2vw var(--pbBxShdw);
  margin: 0 auto;
}
.logoCont:hover {
  width: 2vw;
  height: 2vw;
  box-shadow: 0 0 2vw -0.2vw var(--pbBxShdwHvr);
}
.logoImg {
  width: 100%;
  height: 100%;
}
#img23 {
  width: 100%;
  height: 65%;
}

@keyframes load {
  0% {
    width: 0;
  }
  100% {
    width: var(--lvl);
  }
}

@media (orientation: portrait) {
  :root {
    --text: 2vw;
  }
  #greeting {
    font-size: calc(var(--text) * 2.5);
    margin: 10vw 0 12vw 0;
  }
  #info {
    grid-template: auto auto / 1fr;
    border-radius: 10px;
    margin: 0 7vw;
    width: 86vw;
  }
  #backstory {
    padding: 5vw;
    grid-area: 1/1/2/2;
    width: 76vw;
  }
  .carousel-item.active,
  .carousel-item-next,
  .carousel-item-prev {
    border-top-left-radius: 1.2em;
    border-bottom-left-radius: 0;
    border-top-right-radius: 1.2em;
    border-bottom-right-radius: 0;
  }
  #attrCont {
    grid-area: 2/1/3/2;
    padding: 5vw;
  }
  #aboutMe {
    margin-bottom: 7vw;
  }
  #pokeBadges {
    margin: 0 7vw 7vw 7vw;
    width: auto;
  }
  .logoDiv {
    width: 15vw;
    height: 10vw;
  }
  .logoCont {
    width: 2.5vw;
    height: 2.5vw;
    border: outset var(--logoBorder) 0.5vw;
  }
  .logoCont:hover {
    width: 4vw;
    height: 4vw;
  }
}
